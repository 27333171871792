/* Estilos de Sidebar Estandard */
/* Estilos de Sidebar sin Colapsar */
.Sidebar_button {
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5px;
    color: #050b7f;
}

.Sidebar_button .Icon {
    width: 23pt;
    height: 23pt;
    margin-left: 15px;
    margin-top: 6px;
    cursor: pointer;
}

.Sidebar_content {
    margin-inline: auto;
    margin-top: 1.5rem;
    width: 80%;
    height: 100%;
    color: #050b7f;
}

.Sidebar_content_home {
    height: 1.5rem;

}

.Sidebar_content_home p {
    margin-right: 10px;
    width: fit-content;
    height: fit-content;
    position: relative;
    right: 17px;
    cursor: pointer;
}

.Sidebar_content_home p img {
    position: relative;
    left: 3px;
}


.Sidebar_content p {
    display: flex;
    align-items: center;
    font-size: 10pt;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #050b7f;
    margin-left: 3px;
    margin-top: 7px;
}

.Sidebar_Separador {
    margin-top: 13px;
    height: 0.5px;
    background-color: #050b7f;
}

.Sidebar_content p img {
    height: 23px;
    margin-right: 13px;
}

.Sidebar_content_title {
    font-weight: bold;
}

.sidebar_listItem {
    display: flex;
    width: fit-content;
    align-items: center;
    padding-bottom: 1.5vh;

}

.sidebar_listItem_Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;

}

.sidebar_listItem_text {
    display: flex;
    align-items: center;
    justify-content: start;
    height: 23px;
}

.sidebar_listItem_text p {
    padding-bottom: 6px;
}

.sidebar_category {
    position: relative;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800 !important;
    font-size: 15pt;
    position: relative;
    right: 15px;

}

.Sidebar_categoryList {
    height: calc(100% + 5pt) !important;
    width: 90%;
}

.Sidebar_Title_Module {
    height: 6vh;
}

.Sidebar_Modules {
    justify-content: space-around;
    width: 100%;
    height: 100%;
    margin-inline: auto;
}

/* Estilos de Sidebar Colapsado */
/* SidebarCollapse */

.SidebarCollapse_button {
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SidebarCollapse_button .Icon {
    color: #050b7f;
    width: 23pt;
    height: 23pt;
    cursor: pointer;
}

.SidebarCollapse_content {
    margin-inline: auto;
    margin-top: 2.5rem;
    width: 80%;
    height: 100%;
    color: #050b7f;
}

.SidebarCollapse_content p {
    display: flex;
    margin-inline: auto;
}

.SidebarCollapse_content p img {
    height: 23px;
    margin: auto;
}

.SidebarCollapse_Separador {
    margin-top: 13px;
    margin-inline: auto;
    width: 50%;
    height: 0.5px;
    background-color: #050b7f;
}

.sidebarCollapse_listItem {
    display: flex;
    width: fit-content;
    align-items: center;
    padding-bottom: 1.5vh;
    margin-top: 0.5vh;
}

.Sidebar_categorySection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 25vh;
}

.SidebarCollapse_content_home p {
    width: 80%;
    position: relative;
    right: 4px;
}

.SidebarCollapse_Modules {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: fit-content;
    height: 100%;
    margin-inline: auto;
}

.Sidebar_Popper {
    width: calc(100% + 5pt);
    padding-left: 5px;
    font-size: 10pt;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #050b7f;
    background-color: #D0CFCF;
    border-radius: 3px;
}

@media(max-width: 960px) {
    .Sidebar_button img {
        visibility: hidden;
        display: none;
    }
}

@media(max-height: 600px) {
    .Sidebar_Separador {
        margin-top: 0px;
    }

    .sidebar_listItem {
        margin-top: 5px;
    }
}


@media(min-resolution: 130dpi) {

    .sidebar_listItem {
        height: 1.7em;
        padding-bottom: 0.5vh;
    }

}

@media(min-resolution: 200dpi) {
    .Sidebar_content {
        height: 28rem;
        position: relative;
    }
}

@media(min-resolution: 100dpi) {
    .SidebarCollapse_content {
        position: relative;
        height: 30rem;
        bottom: 20px;
    }
}

@media(min-width: 2000px) {
    .sidebar_listItem {
        height: 2.2em;
        padding-bottom: 0.5vh;
    }
}

.labelLoading {
    width: 160px;
    /* Ancho del contenedor */
    white-space: nowrap;
    /* Evita el salto de línea */
    overflow: hidden;
    /* Oculta el texto que desborda */
    text-overflow: ellipsis;
    /* Agrega los tres puntos (...) al final */
    position: relative;
    /* Necesario para posicionar el pseudo-elemento */
    font-weight: 500;
    margin-top: 25px;
}

.labelLoading::after {
    content: '...';
    /* Contenido del pseudo-elemento */
    position: absolute;
    /* Posicionamiento absoluto con respecto al contenedor */
    bottom: 0;
    /* Coloca los tres puntos en la parte inferior */
    right: 0;
    /* Coloca los tres puntos a la derecha */
    opacity: 0;
    /* Inicialmente oculto */
    animation: fade-in 1s infinite alternate;
    /* Aplica la animación */
}

@keyframes fade-in {
    from {
        opacity: 0;
        /* Opacidad inicial */
    }

    to {
        opacity: 1;
        /* Opacidad final */
    }
}