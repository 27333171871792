.footer{
    display: flex;
    width: 100%;
    height: 3rem;
}

.footer_img{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
}

.footer_container{
    width: 75%;
    height: 100%;
    display: flex;
    padding-top: 1em;
}

.footer_container_menu{
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
}

.footer_container_menu p{
    width: 10vw;
    text-align: center;
}

.footer_logo{
    height: 200%;
    margin-right: 3.75em;
}

.menu_item{
    font-size: 16px;
    color: #BDBDBD;
    border-left: 1px ;
    display: flex;
    font-family: 'Source Sans Pro', sans-serif;
    width: 33%;
    justify-content: center;
    width: fit-content;
}

.menu_item:hover{
    color: #BDBDBD;
}

.copyrigth{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
}

@media(max-width: 950px){
    .footer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: max-content;
    }

    .footer_logo{
        width: 150%;
    }

    .footer_container_menu{
        margin-top: 10px;
        display: block;
        width: 100%;  
    }

    .footer_container_menu p{
        display: none;
    }

    .footer_container{
        display: block;
        padding-left: 3em;
    }

    .menu_item{
        width: 100%;
        justify-content: flex-start;
    }

    .copyrigth{
        width: 100%;
    }

    .footer_img{
        margin-right: 3em;
    }

}