.AcceptCookies_container{
    font-family: 'Source Sans Pro', sans-serif;
}

.AcceptCookies_title{
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #050B7F;
}

@media(max-width: 1300px){ 
    .AcceptCookies_subtitle{
        width: 90%;
    }
}

@media(min-resolution: 130dpi){
    .AcceptCookies_subtitle{
        width: 85%;
    }
}