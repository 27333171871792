.navbar_container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #050b7f !important;
    height: 3rem;
}

.navbar_toolbar{
    justify-content: space-between;
    width: 95%;
    margin: auto;
}

.navbar_logo_txt{
    
    display: flex;
    color: white;
}

.navbar_logo_txt h2{
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 20pt;
    width: max-content;
}

.navbar_logo_txt h4{
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    height: fit-content;
    align-self: center;
    margin-left: 6px;
    font-size: 15pt;
    margin-top: 5pt;
    
}

.navbar_logo_content{
    display: flex;
    align-items: center;
}

.navbar_links{
    width: 15%;
    justify-content: space-between;
}

.navbar_links img,
.navbar_links .Icon{
    height: 20px;
    width: 21px;
    cursor: pointer;
}

.navbar_menuMobil_img{
    height: 100%;
}

.navbar_menuMobil_button{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    width: 15px;
}


.navbar_options{
    height: 20px;
    width: calc(100% + 10pt);
    text-align: center;
    margin-top: 5pt;
    font-size: 10pt;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    color: #050b7f;
    background-color: #D0CFCF;
    border-radius: 3px;
}

.navbar_Notifications{
    height: 10%;
    display: flex;
    align-items: center;
}

.navbar_Notifications p{
    color: #050B7F;
    font-size: 14px;
    font-weight: 600;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    margin-left: 15px;
}

.navbar_menuMobil_Paper{
    background-color: #050b7f;
    text-transform: uppercase;
    width: 15rem;
    height: 3rem;
    margin-top: 1rem;
}

.navbar_menuMobil_Paper div{
    width: 100%;
    height: 100%;
    background-color: #050b7f;
}

.navbar_links_mobil{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.navbar_links_mobil .Icon{
    height: 65%;
}

.navbar_menuMobil_SideBar{
    height: 20px;
    margin-right: 3vw;
    margin-bottom: 3px;
}

@media (max-width: 960px) {
    .navbar_links{
        display: none;
        visibility: hidden;
    }
}

@media (min-width: 960px){
    .navbar_logo_txt{
        position: relative;
        right: 1.3vw;
    }
    .navbar_menuMobil_button,
    .navbar_menuMobil_SideBar{
        display: none;
        visibility: hidden;
    }
}

@media (min-width: 2500px){
    .navbar_logo_txt{
        position: relative;
        right: 2vw;
    }
}

@media (max-width: 440px){
    .navbar_menuMobil{
        margin-right: 10px;
    }
}

@media (max-width: 440px){
    .navbar_menuMobil_img{
        margin-right: 17px;
    }
}

@media (max-width: 300px){
    .navbar_logo_txt h2{
        font-family: 'Montserrat', sans-serif;
        font-weight: 800;
        font-size: 15pt;
    }
    
    .navbar_logo_txt h4{
        font-family: 'Montserrat', sans-serif;
        font-weight: 300;
        height: fit-content;
        align-self: center;
        margin-left: 6px;
        font-size: 10pt;
        margin-top: 5pt;
        
    }
}

@media(max-width: 230px){

    .navbar_menuMobil_SideBar{
        height: 16px;
    }

    .navbar_logo_txt h2{
        font-family: 'Montserrat', sans-serif;
        font-weight: 800;
        font-size: 12pt;
        margin-left: 5px;
        
    }
    
    .navbar_logo_txt h4{
        font-family: 'Montserrat', sans-serif;
        font-weight: 300;
        height: fit-content;
        align-self: center;
        margin-left: 6px;
        font-size: 8pt;
        margin-top: 5pt;
        
    }
}