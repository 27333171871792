.Home_Content {
}

.Home_Card{
    height: calc(100% + 1pt);
}

@media (max-width: 1280px) {
    .Home_Content{
        margin-top: -40px;
    }   
}