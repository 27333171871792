* {
    margin: 0;
    padding: 0;
}

body{
    width: 100%;
    height: 100%;
}

/* Carrusel */

.cb-slideshow,
.cb-slideshow:after { 
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 0; 
}
.cb-slideshow:after { 
    content: '';
}

.cb-slideshow li span { 
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    color: transparent;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: none;
    opacity: 0;
    z-index: 0;
    animation: imageAnimation 76s linear infinite 0s; 
}

.cb-slideshow li div { 
    z-index: 1000;
    position: absolute;
    bottom: 30px;
    left: 0px;
    width: 100%;
    text-align: center;
    opacity: 0;
    color: #fff;
    animation: titleAnimation 76s linear infinite 0s; 
}
.cb-slideshow li div h3 { 
    font-family: 'BebasNeueRegular', 'Arial Narrow', Arial, sans-serif;
    font-size: 240px;
    padding: 0;
    line-height: 200px; 
}

.cb-slideshow li:nth-child(1) span { 
    background-image: url(../../assets/img/loginImg/Carro1.png) 
}
.cb-slideshow li:nth-child(2) span { 
    background-image: url(../../assets/img/loginImg/Carro2.png);
    animation-delay: 6s; 
}
.cb-slideshow li:nth-child(3) span { 
    background-image: url(../../assets/img/loginImg/Carro3.png);
    animation-delay: 12s; 
}
.cb-slideshow li:nth-child(4) span { 
    background-image: url(../../assets/img/loginImg/Carro4.png);
    animation-delay: 18s; 
}
.cb-slideshow li:nth-child(5) span { 
    background-image: url(../../assets/img/loginImg/Tecnologia1.png);
    animation-delay: 24s; 
}
.cb-slideshow li:nth-child(6) span { 
    background-image: url(../../assets/img/loginImg/Tecnologia2.png);
    animation-delay: 30s; 
}

.cb-slideshow li:nth-child(7) span { 
    background-image: url(../../assets/img/loginImg/Tecnologia3.png);
    animation-delay: 36s; 
}

.cb-slideshow li:nth-child(8) span { 
    background-image: url(../../assets/img/loginImg/Tecnologia4.png);
    animation-delay: 42s; 
}

.cb-slideshow li:nth-child(9) span { 
    background-image: url(../../assets/img/loginImg/Sustentabilidade1.png);
    animation-delay: 48s; 
}

.cb-slideshow li:nth-child(10) span { 
    background-image: url(../../assets/img/loginImg/Sustentabilidade2.png);
    animation-delay: 54s; 
}

.cb-slideshow li:nth-child(11) span { 
    background-image: url(../../assets/img/loginImg/Sustentabilidade3.png);
    animation-delay: 60s; 
}

.cb-slideshow li:nth-child(12) span { 
    background-image: url(../../assets/img/loginImg/Sustentabilidade4.png);
    animation-delay: 66s; 
}

.cb-slideshow li:nth-child(2) div { 
    animation-delay: 6s; 
}
.cb-slideshow li:nth-child(3) div { 
    animation-delay: 12s; 
}
.cb-slideshow li:nth-child(4) div { 
    animation-delay: 18s; 
}
.cb-slideshow li:nth-child(5) div { 
    animation-delay: 24s; 
}
.cb-slideshow li:nth-child(6) div { 
    animation-delay: 30s; 
}
.cb-slideshow li:nth-child(7) div { 
    animation-delay: 36s; 
}
.cb-slideshow li:nth-child(8) div { 
    animation-delay: 42s; 
}
.cb-slideshow li:nth-child(9) div { 
    animation-delay: 48s; 
}
.cb-slideshow li:nth-child(10) div { 
    animation-delay: 54s; 
}
.cb-slideshow li:nth-child(11) div { 
    animation-delay: 60s; 
}
.cb-slideshow li:nth-child(12) div { 
    animation-delay: 66s; 
}



/* design form */

.Login_textInput{
    margin-top: 1rem;
}

.welcomeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 4px solid #000383;
    border-left: 4px solid #000383;
    border-radius: 0px 0px 0vh 4vh;
    margin: auto;
    width: 70%;
    height: 8.5rem;
}

.welcomeText{
    padding-left: 1em;
    text-align: left;
    height: 50%;
    width: 100%;
}

.welcomeText h2{
    display: flex;
    align-items: center;
    height: 1em;
    color: #0092FF;
    font-size: 3em;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
}

.subtitleLogin{
    color: #000383;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 11pt;
    margin-left: 2pt;
}

.divImg{
    height: 100%;
    width: 100%;
}


.contRememberSession{
    display: flex;
    width: 100%;
    font-family: 'Source Sans Pro', sans-serif;
    justify-content: space-between;
    margin-top: 15px;
}

.contRememberSession input{
    height: 100%;
}

.divCont{
    display: flex;
    align-items: center;
    height: 100%;
    width: 48em;
    
}

.form{
    margin: auto;
    background-color: rgb(255, 255, 255);
    width: 28em;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.centralMenu{
    width: 70%;
    margin: auto;
}

.labelLoginStyle{
    margin-top: 18px;
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    max-width: 25rem;
    flex-direction: column;
    font-size: 11pt;
    font-family: 'Source Sans Pro', sans-serif !important;
    color: rgb(130,130,130);
}

.textNewPass_login{
    margin-bottom: 10px;
    width: 21em;
}

.rememberPassword{
    text-decoration: underline;
    color: rgb(130,130,130);
    font-size: 12pt;
    font-family: 'Source Sans Pro', sans-serif;
    cursor: pointer;
}

.rememberSession{
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    font-size: 12pt;
    font-family: 'Source Sans Pro', sans-serif;
}

.userTypeCheckBox{
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: #828282
}

.userType{
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    font-size: 13px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #828282;
}

.checkboxNotification:checked {
    background: #56BC23;
    color: white;
  }
  
  .checkboxNotification {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    background: lightgray;
    height: 20px;
    width: 20px;
    border: 1px solid white;
    color: white;
  }
  
  .checkboxNotification:after {
    content: ' ';
    position: relative;
    left: 30%;
    top: 10%;
    width: 20%;
    height: 50%;
    border: 2px solid #fff;
    border-width: 0 2.5px 3px 0;
    transform: rotate(40deg);
    display: none;
  }
  
  .checkboxNotification:checked:after {
    display: block;
  }

.buttonLogin{
    font-family: 'Source Sans Pro', sans-serif;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inputLogin{
    font-family: 'Source Sans Pro', sans-serif !important;
}

.loginLogo{
    margin-top: 1em;
    display: flex;
    justify-content: center;
}

.buttonLogin img{
    width: 50%;
    margin-right: 1em;
    margin-bottom: 1.5em;
}

.MuiInputLabel-root{
    font-family: 'Source Sans Pro', sans-serif;
}

.loginButton{
    text-transform: uppercase;
    background-color: #0092FF;
    border: none;
    border-radius: 8px;
    height: 40px;
    width: 150px;
    outline: 0;
    margin-top: 10px;
    cursor: pointer;
}

.loginButton:hover{
    box-shadow: 0 0 2px 2px grey;
}



@media(min-width: 990px){
    .Login_textInput{
        width: 100%;
    }
    .divImg{
        position: absolute;
        z-index: 0;
    }

    .divCont{
        position: absolute;
        z-index: 1;
    }

    .form{
        border-radius: 20px;
    }
    .Login_textInput{
        width: 22em;
    }
}

@media(max-width: 990px){
    .cb-slideshow{
        display: none;
    }

    .divCont{
        height: 100vh;
        width: 100%;
        background-color: #fff;
    }

    .loginButton{
        margin: 8px auto;
        width: 70vw;
    }
    .textNewPass_login{
        width: 100%;
    }

    
}

@media(min-width: 1700px){
    .divCont{
        height: 100%;
    }
}

@media(max-width: 600px){
    .subtitleLogin{
        font-size: 9pt;
    }

}

@media(max-width: 300px){
    .welcomeText h2{
        font-size: 2em;
    }
}


/* Animacion */

@keyframes imageAnimation { 
    0% { opacity: 0; animation-timing-function: ease-in; }
    8% { opacity: 1; animation-timing-function: ease-out; }
    17% { opacity: 1 }
    25% { opacity: 0 }
    100% { opacity: 0 }
}