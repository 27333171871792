.report-style-class {
	height: 95vh;
	margin: 1% auto;
	width: 100%;
}

body {
	font-family: 'Segoe UI';
	margin: 0;
}

.header {
	background: #3476AE 0 0 no-repeat padding-box;
	border: 1px solid #707070;
	height: 55px;
	left: 0;
	top: 0;
}

.controls {
	margin-top: 1%;
	text-align: center;
}

button {
	background: #337AB7;
	border: 0;
	border-radius: 5px;
	color: #FFFFFF;
	font-size: medium;
	height: 35px;
	margin-right: 15px;
}

button:onfocus {
	outline: none;
}

iframe {
	border: none;
}